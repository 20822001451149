import React from "react";
import "./footer.css";
import { MDBFooter, MDBContainer } from "mdb-react-ui-kit";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import Logo from "../assets/logo-white.webp";
import BestWeb from "../assets/bestweb-winner-logo.png";
import TopWeb from "../assets/TopWeb-June-2024.webp";

export default function Footer() {
  return (
    <MDBFooter className="footer">
      <MDBContainer className="p-4 pb-0">
        <section>
          <div className="flex">
            <div className="left">
              <div className="footer-pc">
                <p className="footer-para">
                  <a href="/cookies-policy/">COOKIE POLICY</a>
                  <a href="/privacy-policy/">PRIVACY POLICY</a>
                  <a href="/sitemap/">SITEMAP</a>
                  <a href="/contact-us/">CONTACT US</a>
                </p>
              </div>

              <div className="footer-mobile">
                <p className="footer-para">
                  <a href="/cookies-policy/">COOKIE POLICY</a>
                  <a href="/privacy-policy/">PRIVACY POLICY</a>
                  <a href="/sitemap/">SITEMAP</a>
                  <br />
                  <br />
                  <a href="/contact-us/">CONTACT US</a>
                </p>
              </div>
            </div>

            <div className="right right-flex">
              <div className="icons_circle-footer">
                <a
                  href="https://www.facebook.com/HirdaramaniGroup/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <FaFacebook size={15} />
                </a>
              </div>

              <div className="icons_circle-footer">
                <a
                  href="https://www.instagram.com/hirdaramanigroup/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <FaInstagram size={15} />
                </a>
              </div>

              <div className="icons_circle-footer">
                <a
                  href="https://www.linkedin.com/company/hirdaramani-group-of-companies/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                >
                  <FaLinkedinIn size={15} />
                </a>
              </div>

              <div className="icons_circle-footer">
                <a
                  href="https://www.youtube.com/channel/UCS8MIl0YX4y5hRI7JkXoUOw"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="YouTube"
                >
                  <FaYoutube size={15} />
                </a>
              </div>
            </div>
          </div>
        </section>
      </MDBContainer>

      <div className="footer-img">
        <div className="footer-firm-logo">
          <div className="bestweb">
            <img
              src={BestWeb}
              alt="Most Popular-Best Corporate Social Responsibility Website"
            />
          </div>
          <div className="topweb">
            <a
              href="https://topweb.lk/june2024/hirdaramani-sustainability/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={TopWeb} alt="TopWeb Logo" />
            </a>
          </div>
        </div>
        <div className="footer-firm-logo hirdaramani">
          <a href="/">
            <img src={Logo} alt="Hirdaramani Logo" />
          </a>
        </div>
        <div className="empty-column"></div>
      </div>

      <div className="footer-pc">
        <div
          className="bottom-footer text-center p-3"
          style={{ backgroundColor: "#313893" }}
        >
          <a className="text-white" href="http://3cs.lk">
            Web Design by 3CS &nbsp; &nbsp; | &nbsp; &nbsp;
          </a>
          Copyright © 2025 Hirdaramani Group
        </div>
      </div>

      <div className="footer-mobile">
        <center>
          <br />
          <div className="mo-text" style={{ backgroundColor: "#313893" }}>
            <a className="text-white" href="http://3cs.lk">
              Web Design by 3CS
            </a>
            <br />
            <br />
            Copyright © 2025 Hirdaramani Group
            <br />
          </div>
        </center>
      </div>
    </MDBFooter>
  );
}
